import React, { useState, useContext } from "react"
import clsx from "clsx"
import { makeComponentStyles } from "@components/mixins"
import Layout from "@components/Layout/Layout"
import Container from "@components/Container/Container.jsx"
import MainWrap from "@components/Wrap/MainWrap"
import Button from "@components/Buttons/Button.jsx"
import IconButton from "@components/Buttons/IconButton"
import { Typography, Grid, useMediaQuery, makeStyles } from "@material-ui/core"
import NoProfile from "@static/images//NoProfile.svg"
import { graphql } from "gatsby";
import Markdown from 'markdown-to-jsx';
import { IntlContextConsumer, useIntl } from "gatsby-plugin-react-intl"
import { getStrapiContentByLang } from "../heplers.js"
import FloatWrap from "@components/Wrap/FloatWrap.jsx";
import Seo from "@components/seo"
import ButtonLink from "@components/Buttons/ButtonLink.jsx"


const styles = ({ palette, breakpoints }) => ({
  // <<<<<<<<<<<<<<<< Блок "О нас"  >>>>>>>>>>>>>>>>>>>>>
  about__container: {
    display: "flex",
    flexDirection: "column",
  },
  about__photo: {
    paddingBottom: "80px",
    [breakpoints.down("md")]: {
      paddingBottom: "24px",
    }
  },
  about__links: {
    paddingBottom: "50px",
    [breakpoints.down("sm")]: {
      paddingBottom: "0px",
    }
  },
  about__link_height: {
    height: "87px",
    borderBottom: `1px solid ${palette.primary.main}`,
    [breakpoints.down("sm")]: {
      height: "56px",
    }
  },
  about__without_border: {
    border: "none",
  },
  about__other_link: {
    display: "flex",
    justifyContent: "flex-end",
    padding: "0 15px",
    [breakpoints.down("sm")]: {
      justifyContent: "flex-start",
      paddingLeft: "0px",
    },
  },
  about__box_link: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    width: "100%",
  },
  // <<<<<<<<<<<<<<<< Блок "Прибытие в Томск. Помощь в заселении"  >>>>>>>>>>>>>>>>>>>>>
  arrival__container: {
    display: "flex",
    flexDirection: "column",
  },
  arrival__header: {
    paddingBottom: "115px",
    [breakpoints.down("md")]: {
      paddingBottom: "20px"
    }
  },
  arrival__header_text: {
    paddingLeft: "100px",
    lineHeight: "23px",
    [breakpoints.down("md")]: {
      paddingTop: "20px",
      paddingLeft: "0px",
      lineHeight: "17px"
    }
  },
  arrival__info: {
    display: "flex",
  },
  arrival__info_text: {
    display: "flex",
    flexDirection: "column",
    [breakpoints.down("md")]: {
      width: "100%"
    }
  },
  arrival__map_info: {
    paddingBottom: "58px",
  },
  arrival__document_link_under: {
    textDecoration: "underline",
  },
  arrival__document_info: {
    display: "flex",
    flexDirection: "column",
  },
  arrival__document_header: {
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "20px",
    lineHeight: "24px",
    paddingBottom: "26px",
    [breakpoints.down("md")]: {
      fontSize: "14px",
      lineHeight: "17px",
    }
  },
  arrival__button: {
    marginTop: "15px",
    height: "50px",
    [breakpoints.down("sm")]: {
      width: "170px"
    }
  },
  arrival__document_img: {
    width: "532px",
    display: "flex",
    justifyContent: "center",
    [breakpoints.down("md")]: {
      display: "none",
    }
  },
  arrival__document_link: {
    // marginBottom: "17px",
    color: palette.primary.main,
    fontSize: "18px !important",
    lineHeight: "21px !important",
    [breakpoints.down("md")]: {
      fontSize: "14px !important",
      lineHeight: "17px !important",
    }
  },
  // <<<<<<<<<<<<<<<< Блок "Наши сотрудники"  >>>>>>>>>>>>>>>>>>>>>
  worker__box_icon: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    textAlign: "center"
  },
  worker__btn_icon: {
    width: "134px",
    height: "134px",
    "&::before": {
      content: '""',
      position: "absolute",
      zIndex: 1,
      borderRadius: "50%",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      backgroundSize: "cover",
    },
  },
  worker__speciality: {
    paddingTop: "10px",
  },
  worker__name: {
    paddingTop: "10px",
    fontWeight: "500",
    fontSize: "18px",
    lineHeight: "21px",
  },
  // <<<<<<<<<<<<<<<< Общие классы  >>>>>>>>>>>>>>>>>>>>>
  desktop_text: {
    fontSize: "18px !important",
    lineHeight: "21px",
    fontWeight: "400",
  }
})

const makeImage = (url) => makeStyles({
  root: {
    "&::before": {
      background: `url(${url ? url : NoProfile}) center no-repeat`,
    },
  }
})().root;

function go(link) {
  window.open(link, "_blank")
}

const About = ({ data }) => {
  const {
    // <<<<<<<<<<<<<<<< Блок "О нас"  >>>>>>>>>>>>>>>>>>>>>
    about__container,
    about__photo,
    about__links,
    about__link_height,
    about__without_border,
    about__other_link,
    about__box_link,
    // <<<<<<<<<<<<<<<< Блок "Прибытие в Томск. Помощь в заселении"  >>>>>>>>>>>>>>>>>>>>>
    arrival__container,
    arrival__header,
    arrival__header_text,
    arrival__info,
    arrival__info_text,
    arrival__map_info,
    arrival__document_info,
    arrival__document_header,
    arrival__button,
    arrival__document_img,
    arrival__document_link,
    // <<<<<<<<<<<<<<<< Блок "Наши сотрудники"  >>>>>>>>>>>>>>>>>>>>>
    worker__box_icon,
    worker__btn_icon,
    worker__speciality,
    worker__name,
    desktop_text,
  } = makeComponentStyles(styles);

  const isMobile = useMediaQuery(theme => theme.breakpoints.down('sm'));
  const [visible, setVisible] = useState(false);
  const { language } = useContext(IntlContextConsumer);
  const intl = useIntl();

  const {
    AboutTitle,
    AboutImage,
    AboutInfo,
    helpers,
    ArrivalTitle,
    ArrivalImage,
    Arrivalnfo,
    WorkersTitle,
    Workers,
    ArrivalInfoCity,
    ArrivalBrochures,
    ArrivalBrochuresImage,
    BrochuresList,
    AboutSEO
  } = getStrapiContentByLang(data.allStrapiAbout.edges, language);

  // console.log(data.allStrapiAbout.edges);

  return (
    <div>
      <Seo title={AboutSEO?.TitleSEO}
           description={AboutSEO?.DescriptionSEO}
           keywords={AboutSEO?.KeywordsSEO.split(',')}
      />

      <>
        <Layout>
          {/*<<<<<<<<<<<<<<<< Блок "О нас"  >>>>>>>>>>>>>>>>>>>>>*/}
          <Container mw={"lg"}>
            <MainWrap>
              <MainWrap.Header>
                <Typography component={"h1"} variant={"h2"} style={{ color: "#000" }}>
                  {AboutTitle}
                </Typography>
              </MainWrap.Header>
              <MainWrap.Body>
                <div className={about__container}>
                  <FloatWrap className={about__photo} Img={AboutImage.localFile.publicURL} breakpoint='md'>
                    <Markdown>
                      {AboutInfo}
                    </Markdown>
                  </FloatWrap>

                  <div className={about__links}>
                    <Grid container>
                      <Grid item md={12}>
                        <Grid container spacing={3}>
                          {
                            helpers.map(({ HelpName }) => (
                              <Grid style={{ paddingTop: "0px", paddingBottom: "0px" }} item md={4} xs={12}>
                                <div className={clsx(about__link_height, about__box_link)}>
                                  <Typography className={clsx(desktop_text)}>{HelpName}</Typography>
                                </div>
                              </Grid>
                            ))
                          }
                        </Grid>
                      </Grid>
                      <Grid container >
                        <div className={clsx(about__other_link, about__link_height, about__box_link, about__without_border)}>
                          <Typography className={clsx(desktop_text)}>И многое другое...</Typography>
                        </div>
                      </Grid>
                    </Grid>
                  </div>
                </div>
              </MainWrap.Body>
            </MainWrap>
          </Container>
          {/*<<<<<<<<<<<<<<<< Блок "Прибытие в Томск. Помощь в заселении"  >>>>>>>>>>>>>>>>>>>>>*/}
          <Container mw={"lg"}>
            <MainWrap>
              <MainWrap.Header>
                <Typography component={"h1"} variant={"h2"} style={{ color: "#000" }}>
                  {ArrivalTitle}
                </Typography>
              </MainWrap.Header>
              <MainWrap.Body>
                <div className={arrival__container}>
                  <FloatWrap className={arrival__header} Img={ArrivalImage.localFile.publicURL} breakpoint='md'>
                    <Markdown>
                      {Arrivalnfo}
                    </Markdown>
                  </FloatWrap>

                  <div className={arrival__info}>
                    <div className={arrival__info_text}>
                      <div className={arrival__map_info}>
                        <Typography >
                          <Markdown>{ArrivalInfoCity}</Markdown>
                        </Typography>
                      </div>
                      <div className={arrival__document_info}>
                        <Typography className={arrival__document_header}>
                          {ArrivalBrochures}
                        </Typography>
                        <div hidden={(isMobile && !visible) ? true : false}>
                          <Grid container spacing={2} style={{ paddingRight: '100px' }}>
                            {
                              BrochuresList.map(({ BrochureName, File }) => (
                                <Grid item xs={12} md={6}>
                                  <ButtonLink className={arrival__document_link} onClick={() => go(File?.localFile?.publicURL)}>{BrochureName}</ButtonLink>
                                </Grid>
                              ))
                            }
                          </Grid>
                        </div>
                        <div align='center' hidden={isMobile ? false : true}>
                          <Button className={clsx(arrival__button, desktop_text)} onClick={() => setVisible(!visible)}>
                            {visible ? intl.formatMessage({ id: "hide" }) : intl.formatMessage({ id: "show" })}
                          </Button>
                        </div>
                      </div>
                    </div>
                    <div className={arrival__document_img}>
                      <img src={ArrivalBrochuresImage.localFile.publicURL} alt="Документы" />
                    </div>
                  </div>
                </div>
              </MainWrap.Body>
            </MainWrap>
          </Container>
          {/*<<<<<<<<<<<<<<<< Блок "Наши сотрудники"  >>>>>>>>>>>>>>>>>>>>>*/}
          <Container mw={"lg"}>
            <MainWrap>
              <MainWrap.Header>
                <Typography component={"h1"} variant={"h2"} style={{ color: "#000" }}>
                  {WorkersTitle}
                </Typography>
              </MainWrap.Header>
              <MainWrap.Body>
                <Grid container spacing={2}>
                  {
                    Workers.map(({ Work, MiddleName, Surname, Name, WorkerImage }) => (
                      <Grid item xs={6} md={4}>
                        <div className={worker__box_icon}>
                          <IconButton color="primary" className={clsx(worker__btn_icon, makeImage(
                            WorkerImage ? WorkerImage.localFile.publicURL : null
                          ))}></IconButton>
                          <Typography className={worker__name}>{Name} {MiddleName} {Surname}</Typography>
                          <Typography className={clsx(worker__speciality, desktop_text)}>
                            <Markdown>{Work}</Markdown>
                          </Typography>
                        </div>
                      </Grid>
                    ))
                  }
                </Grid>
              </MainWrap.Body>
            </MainWrap>
          </Container>
        </Layout>
      </>
    </div>
  )
}

export default About
export const pageQuery = graphql`{
  allStrapiAbout {
    edges {
      node {
        locale
        AboutTitle
        AboutInfo
        AboutImage {
          localFile {
            publicURL
          }
        }
        helpers {
          HelpName
        }
        ArrivalTitle
        ArrivalImage {
          localFile {
            publicURL
          }
        }
        Arrivalnfo
        ArrivalInfoCity
        ArrivalBrochures
        ArrivalBrochuresImage {
          localFile {
            publicURL
          }
        }
        BrochuresList {
          BrochureName
          File {
            localFile {
              publicURL
            }
          }
        }
        WorkersTitle
        Workers {
          Surname
          Name
          MiddleName
          Work
          WorkerImage {
            localFile {
              publicURL
            }
          }
        }
        AboutSEO {
          DescriptionSEO
          KeywordsSEO
          TitleSEO
        }
      }
    }
  }
}`;
